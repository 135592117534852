import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import './AsyncCustomButton.css';

// Inspired by https://ironeko.com/posts/how-to-asynchronous-buttons-with-loading-state-in-react
export class AsyncCustomButton extends Component {
    static displayName = AsyncCustomButton.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    async handleButtonClick(e) {
        if (this.state.loading) {
            console.log("debounce!");
            return;
        }

        if (this.props.onClick) {
            // this doesn't seem to work in optimized production builds!
            const isPromise = true;//this.props.onClick.constructor.name === "AsyncFunction";
            if (isPromise)
            {
                this.setState({loading: true});
                await this.props.onClick(e);
                this.setState({loading: false});
            }
            else
            {
                await this.props.onClick(e);
            }
        }
    }

    render() {
        let content = this.state.loading ?
        (
            // kind of a hack that we are hardcoding a convenient width here
            <span style={{ height: 28, width: 57 }}>
              <svg
                id="loading"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none" />
                <path
                  d="M168,40.7a96,96,0,1,1-80,0"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="28"/>
              </svg>
            </span>
          ) : (<span style={{ whiteSpace: "nowrap" }}>{this.props.children}</span>);

          let disabled = this.props.disabled || this.state.loading;
        
          return (
            <Button variant="contained" color="primary" disabled={disabled} onClick={this.handleButtonClick}>{content}</Button>
        );
    }
}