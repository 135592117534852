import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Tooltip } from '@material-ui/core';
import { AsyncCustomButton } from './AsyncCustomButton';

const BigTooltip = withStyles( (theme) => ({
    tooltip: {
        fontSize: theme.typography.pxToRem(13)
    }
}))(Tooltip);

export class RecruitMadlib extends Component {
    static displayName = RecruitMadlib.name;

    constructor(props) {
        super(props);

        this.state = {
            leadName: props.leadName,
            transactionType: props.defaultTransactionType,

            agents: props.agents,

            disabled: props.disabled,
            
            selectedAgents: {
                new: props.defaultRecruiter,
                experienced: props.defaultRecruiter
            }
        };

        this.handleTransactionChange = this.handleTransactionChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);

        /*
            Our props will need:
                Lead Name
                List of agents (for now use same for buyer/seller)
                Default transaction type
        */
    }

    static getDerivedStateFromProps(props, state) {
        /* TODO:  apparently this should compare existing state/props first
            so that we don't load too much
        */

        let newState = {
            leadName: props.leadName,
            agents: props.agents,
            recruitingDisabled: props.recruitingDisabled,
            
            selectedAgents: {
                new: state.selectedAgents.new,
                experienced: state.selectedAgents.experienced
            }
        };

        if (state.selectedAgents.new == 0) {
            newState.selectedAgents.new = props.defaultRecruiter;
        }

        if (state.selectedAgents.experienced == 0) {
            newState.selectedAgents.experienced = props.defaultRecruiter;
        }
        
        return newState;
    }

    handleTransactionChange(e) {
        let transactionType = e.target.value;
        
        this.setState((state, props) => ({
            transactionType: transactionType
        }));
    }

    handleAgentChange(transactionType, e){
        let assignedAgent = e.target.value;
        this.setState((state, props) => {
            
            let newAgents = {
                new: state.selectedAgents.new,
                experienced: state.selectedAgents.experienced
            };

            newAgents[transactionType] = assignedAgent;
            return {selectedAgents: newAgents};
        });
    }

    async handleButtonClick(e) {
        let transactions = [];
        
        transactions.push({transactionType: this.state.transactionType, agentId: this.state.selectedAgents[this.state.transactionType]});

        if (this.props.callback) {
            await this.props.callback(transactions);
        }
    }

    agents(transactionType) {
        let selectedValue = this.state.selectedAgents[transactionType];
        let agents = this.state.agents.map((agent) => <MenuItem key={agent.agentId} value={agent.agentId}>{agent.name}</MenuItem>);
        let disabled = this.props.disabled;
        
        return (<Select variant="filled" disabled={disabled} value={selectedValue} onChange={(e) => this.handleAgentChange(transactionType, e)} style={{margin: '7px' }}>
            {agents}
        </Select>);
    }

    render() {
        let disabled = this.props.disabled;
        
        let transactionSelect = (
        <Select variant="filled" value={this.state.transactionType} onChange={this.handleTransactionChange} style={{margin: '7px' }} disabled={disabled}>
            <MenuItem value="new">New recruit</MenuItem>
            <MenuItem value="experienced">Experienced recruit</MenuItem>
        </Select>);

        let createButton = <AsyncCustomButton onClick={this.handleButtonClick} disabled={disabled}>Create</AsyncCustomButton>;
        
        let madlib = (<div>
                Create new {transactionSelect} for {this.state.leadName} assigned to {this.agents(this.state.transactionType)}
                {createButton}
            </div>);

        let content = disabled
        ? <BigTooltip placement="left" arrow title="You cannot create more than one recruit at a time.  To make changes, just edit the existing recruit.">
            {madlib}
        </BigTooltip>
        : madlib;

        return content;
    }
}