import React, { Component } from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { TransactionMadlib } from './TransactionMadlib';
import { RecruitMadlib } from './RecruitMadlib';
import { HybridMadlib } from './HybridMadlib';

const transactionColumns /*: GridColDef[]*/ = [
    { field: 'id', headerName: 'ID', minWidth: 80 },
    { field: 'dateCreated', headerName: 'Created', minWidth: 110 },
    { field: 'agent', headerName: 'Agent', minWidth: 150, flex: 1 },
    { field: 'status', headerName: 'Status', minWidth: 80, flex: 1 },
    { field: 'transactionType', headerName: 'Transaction Type', minWidth: 180 },
    { field: 'addressStreet', headerName: 'Street', minWidth: 140, flex: 1 },
    { field: 'addressCity', headerName: 'City', minWidth: 120, flex: 1 },
    { field: 'addressState', headerName: 'State', minWidth: 100 },
    { field: 'addressZip', headerName: 'ZIP', minWidth: 100 },
    { field: 'link', headerName: 'Link', minWidth: 60, renderCell: (params) => (<a href={params.value}>Edit</a>)},
];

const recruitingColumns /*: GridColDef[]*/ = [
    { field: 'id', headerName: 'ID', minWidth: 80 },
    { field: 'dateCreated', headerName: 'Created', minWidth: 110 },
    { field: 'agent', headerName: 'Recruiter', minWidth: 150, flex: 1 },
    { field: 'status', headerName: 'Status', minWidth: 80, flex: 1 },
    { field: 'transactionType', headerName: 'Recruit Type', minWidth: 180 },
    { field: 'addressStreet', headerName: 'Street', minWidth: 140, flex: 1 },
    { field: 'addressCity', headerName: 'City', minWidth: 120, flex: 1 },
    { field: 'addressState', headerName: 'State', minWidth: 100 },
    { field: 'addressZip', headerName: 'ZIP', minWidth: 100 },
    { field: 'link', headerName: 'Link', minWidth: 60, renderCell: (params) => (<a href={params.value}>Edit</a>)},
];

export class Bridge extends Component {
    static displayName = Bridge.name;

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isPosting: false,

            leadName: "",
            defaultTransactionType: "buyer",

            transactionDetail: {
                agents: [],
                transactions: [],
                defaultBuyerAgent: 0,
                defaultSellerAgent: 0
            },

            recruitingDetail: {
                agents: [],
                transactions: [],
                defaultRecruiter: 0
            }
        };

        this.handleMadlib = this.handleMadlib.bind(this);
    }

    componentDidMount() {
        this.fetchDataFromServer();
    }

    renderRecruits() {
        // TODO:  this is dumb, how do we set the height dynamically?
        let tableHeight = 65 + 55 * (1+Math.max(this.state.recruitingDetail.transactions.length, 1));

        let recruitingDisabled = this.state.recruitingDetail.transactions.length > 0;

        return (<div>
            <h2 style={{paddingBottom: '10px'}}>Sisu recruit for {this.state.leadName}:</h2>

            <div style={{ display: 'flex', height: tableHeight, paddingTop: '10px'}}>
                <div style={{ flexGrow: 1}}>
                    <DataGrid rows={this.state.recruitingDetail.transactions} columns={recruitingColumns} disableSelectionOnClick disableColumnMenu rowsPerPageOptions={[]} autoPagesize={true} hideFooter={true} hideFooterPagination={true} />
                </div>
            </div>

            <div style={{paddingTop: '40px', float: 'right'}}>
                <RecruitMadlib
                    leadName={this.state.leadName}
                    defaultTransactionType={this.state.defaultTransactionType}

                    agents={this.state.recruitingDetail.agents}
                    defaultRecruiter={this.state.recruitingDetail.defaultRecruiter}

                    disabled={recruitingDisabled}
                    
                    callback={this.handleMadlib} />
            </div>

            <p style={{clear: 'both', paddingTop: '20px'}}>
                <strong>IMPORTANT - </strong> Please see the <strong>“How To Win With This Integration”</strong> section in the <a href="https://kb.sisu.co/en/articles/9024257-ins-and-outs-of-the-lofty-chime-integration" target="_blank">Knowledge Base article</a> to ensure you are following the correct workflow.
            </p>
        </div>);
    }

    renderTransactions() {
        // TODO:  this is dumb, how do we set the height dynamically?
        let tableHeight = 65 + 55 * (1+Math.max(this.state.transactionDetail.transactions.length, 1));

        return (<div>
            <h2 style={{paddingBottom: '10px'}}>"Open" Sisu transactions for {this.state.leadName}:</h2>
            <p><em>Note</em> - <em><strong>Closed</strong></em> and <em><strong>Lost</strong></em> Sisu transactions are <em><strong>NOT</strong></em> shown.</p>
                
            <div style={{ display: 'flex', height: tableHeight, paddingTop: '10px'}}>
                <div style={{ flexGrow: 1}}>
                    <DataGrid rows={this.state.transactionDetail.transactions} columns={transactionColumns} disableSelectionOnClick disableColumnMenu rowsPerPageOptions={[]}/>
                </div>
            </div>
            
            <div style={{paddingTop: '40px', float: 'right'}}>
                <TransactionMadlib
                    leadName={this.state.leadName}
                    defaultTransactionType={this.state.defaultTransactionType}

                    agents={this.state.transactionDetail.agents}
                    
                    defaultBuyerAgent={this.state.transactionDetail.defaultBuyerAgent}
                    defaultSellerAgent={this.state.transactionDetail.defaultListingAgent}
                    
                    callback={this.handleMadlib} />
            </div>

            <p style={{clear: 'both', paddingTop: '20px'}}>
                <strong>IMPORTANT - </strong> Please see the <strong>“How To Win With This Integration”</strong> section in the <a href="https://kb.sisu.co/en/articles/9024257-ins-and-outs-of-the-lofty-chime-integration" target="_blank">Knowledge Base article</a> to ensure you are following the correct workflow.
            </p>

        </div>);
    }

    renderHybrid() {
        let transactionHeight = 65 + 55 * (1+Math.max(this.state.transactionDetail.transactions.length, 1));
        let recruitHeight = 65 + 55 * (1+Math.max(this.state.recruitingDetail.transactions.length, 1));

        let recruitingDisabled = this.state.recruitingDetail.transactions.length > 0;

        return (<div>
            <h2 style={{paddingBottom: '10px'}}>"Open" Sisu transactions for {this.state.leadName}:</h2>
            <p><em>Note</em> - <em><strong>Closed</strong></em> and <em><strong>Lost</strong></em> Sisu transactions are <em><strong>NOT</strong></em> shown.</p>
                
            <div style={{ display: 'flex', height: transactionHeight, paddingTop: '10px'}}>
                <div style={{ flexGrow: 1}}>
                    <DataGrid rows={this.state.transactionDetail.transactions} columns={transactionColumns} disableSelectionOnClick disableColumnMenu rowsPerPageOptions={[]} autoPagesize={true} />
                </div>
            </div>

            <h2 style={{marginTop: '35px', paddingBottom: '10px'}}>Sisu recruit for {this.state.leadName}:</h2>
            <div style={{ display: 'flex', height: recruitHeight, paddingTop: '10px'}}>
                <div style={{ flexGrow: 1}}>
                    <DataGrid rows={this.state.recruitingDetail.transactions} columns={recruitingColumns} disableSelectionOnClick disableColumnMenu rowsPerPageOptions={[]} autoPagesize={true} hideFooter={true} hideFooterPagination={true} />
                </div>
            </div>
            
            <div style={{paddingTop: '40px', float: 'right'}}>
                <HybridMadlib
                    leadName={this.state.leadName}
                    defaultTransactionType={this.state.defaultTransactionType}
                    
                    transactionAgents={this.state.transactionDetail.agents}
                    defaultBuyerAgent={this.state.transactionDetail.defaultBuyerAgent}
                    defaultSellerAgent={this.state.transactionDetail.defaultListingAgent}
                    
                    recruitingAgents={this.state.recruitingDetail.agents}
                    defaultRecruiter={this.state.recruitingDetail.defaultRecruiter}

                    recruitingDisabled={recruitingDisabled}

                    callback={this.handleMadlib} />
            </div>

            <p style={{clear: 'both', paddingTop: '20px'}}>
                <strong>IMPORTANT - </strong> Please see the <strong>“How To Win With This Integration”</strong> section in the <a href="https://kb.sisu.co/en/articles/9024257-ins-and-outs-of-the-lofty-chime-integration" target="_blank">Knowledge Base article</a> to ensure you are following the correct workflow.
            </p>

        </div>);
    }

    render() {
        
        let isRecruiting = this.state.recruitingDetail != null;
        let isHybrid = this.state.recruitingDetail && this.state.transactionDetail;

        let content = this.state.isLoading
        ? (<div>loading...</div>)
        : isHybrid
            ? this.renderHybrid()
            : isRecruiting
                ? this.renderRecruits()
                : this.renderTransactions();

        return (
            <div style={{width: '1350px', padding: '20px'}}>
                {content}
            </div>
        )
    }

    async fetchDataFromServer() {
        const token = this.props.token;
        const response = await fetch('transactions/' + token);
        const data = await response.json();

        this.setState((state, props) => {
            return { ...data, isLoading: false };
        });
    }

    async handleMadlib(transactionsToCreate) {
        //console.log(transactionsToCreate);
        if (this.state.isPosting)
            return;
        
        this.setState({isPosting: true});
        
        const token = this.props.token;
        const response = await fetch('transactions/' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({transactionsToCreate})
        })
        const data = await response.json();

        /* Deep-clone our details and then update with new transactions from the server */
        let transactionDetail = this.state.transactionDetail;
        if (transactionDetail) {
            transactionDetail = JSON.parse(JSON.stringify(transactionDetail));
            transactionDetail.transactions = data.transactions;
        }

        let recruitingDetail = this.state.recruitingDetail;
        if (recruitingDetail) {
            recruitingDetail = JSON.parse(JSON.stringify(recruitingDetail));
            recruitingDetail.transactions = data.recruitingTransactions;
        }

        this.setState({
            transactionDetail: transactionDetail,
            recruitingDetail: recruitingDetail,
            isPosting: false,
            isLoading: false
        });
    }
}