import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Bridge } from './components/Bridge';
import { TokenContext } from './components/TokenContext';
import queryString from 'query-string';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    console.log(window.location);

    let query = queryString.parse(window.location.search);
    console.log(query);

    this.state = {
      token: query.link
    };
  }

  /*render () {
    return (
      <Layout>
        <TokenContext.Provider value={this.state.token}>
          <Route exact path='/' component={Home} />
          <Route path='/counter' component={Counter} />
          <Route path='/fetch-data' component={FetchData} />
          <Route path='/bridge' component={BridgeWrapper} />
        </TokenContext.Provider>
      </Layout>
    );
  }*/

  render () {
    return(
      <Bridge token={this.state.token}></Bridge>
    );
  }
}

function BridgeWrapper() {
  return (
    <TokenContext.Consumer>
        { token => (
            <Bridge token={token} />
        )}
    </TokenContext.Consumer>
  );
}