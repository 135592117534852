import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Tooltip } from '@material-ui/core';
import { AsyncCustomButton } from './AsyncCustomButton';

const BigTooltip = withStyles( (theme) => ({
    tooltip: {
        fontSize: theme.typography.pxToRem(13)
    }
}))(Tooltip);

export class HybridMadlib extends Component {
    static displayName = HybridMadlib.name;

    constructor(props) {
        super(props);

        this.state = {
            leadName: props.leadName,
            transactionType: props.defaultTransactionType,
            
            transactionAgents: props.transactionAgents,
            recruitingAgents: props.recruitingAgents,

            recruitingDisabled: props.recruitingDisabled,
            
            selectedAgents: {
                buyer: props.defaultBuyerAgent,
                seller: props.defaultSellerAgent,
                new: props.defaultRecruiter,
                experienced: props.defaultRecruiter,
            }
        };

        this.handleTransactionChange = this.handleTransactionChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);

        /*
            Our props will need:
                Lead Name
                List of agents (for now use same for buyer/seller)
                Default transaction type
        */
    }

    static getDerivedStateFromProps(props, state) {
        /* TODO:  apparently this should compare existing state/props first
            so that we don't load too much
        */

        let newState = {
            leadName: props.leadName,
            
            transactionAgents: props.transactionAgents,
            recruitingAgents: props.recruitingAgents,

            recruitingDisabled: props.recruitingDisabled,

            selectedAgents: {
                buyer: state.selectedAgents.buyer,
                seller: state.selectedAgents.seller,
                new: state.selectedAgents.new,
                experienced: state.selectedAgents.experienced
            }
        };

        if (state.selectedAgents.buyer == 0) {
            newState.selectedAgents.buyer = props.defaultBuyerAgent;
        }

        if (state.selectedAgents.seller == 0) {
            newState.selectedAgents.seller = props.defaultSellerAgent;
        }

        if (state.selectedAgents.new == 0) {
            newState.selectedAgents.new = props.defaultRecruiter;
        }

        if (state.selectedAgents.experienced == 0) {
            newState.selectedAgents.experienced = props.defaultRecruiter;
        }
        
        return newState;
    }

    handleTransactionChange(e) {
        let transactionType = e.target.value;
        
        this.setState((state, props) => ({
            transactionType: transactionType
        }));
    }

    handleAgentChange(transactionType, e){
        let assignedAgent = e.target.value;
        this.setState((state, props) => {
            
            let newAgents = {
                buyer: state.selectedAgents.buyer,
                seller: state.selectedAgents.seller,
                new: state.selectedAgents.new,
                experienced: state.selectedAgents.experienced
            };

            newAgents[transactionType] = assignedAgent;
            return {selectedAgents: newAgents};
        });
    }

    async handleButtonClick(e) {
        let transactions = [];
        
        if (this.state.transactionType == "buyerAndSeller") {
            transactions.push({transactionType: "buyer", agentId: this.state.selectedAgents.buyer});
            transactions.push({transactionType: "seller", agentId: this.state.selectedAgents.seller});
        } else {
            transactions.push({transactionType: this.state.transactionType, agentId: this.state.selectedAgents[this.state.transactionType]});
        }

        if (this.props.callback) {
            await this.props.callback(transactions);
        }
    }

    agents(transactionType) {
        let selectedValue = this.state.selectedAgents[transactionType];
        
        let agents =
            (transactionType === "new" || transactionType === "experienced")
            ? this.state.recruitingAgents
            : this.state.transactionAgents;

        let menuItems = agents.map((agent) => <MenuItem key={agent.agentId} value={agent.agentId}>{agent.name}</MenuItem>);
        
        return (<Select variant="filled" value={selectedValue} onChange={(e) => this.handleAgentChange(transactionType, e)} style={{margin: '7px' }}>
            {menuItems}
        </Select>);
    }

    render() {
        
        /* We unfortunately have to duplicate these selects, because in the happy path where all menu items are enabled,
        they must be direct children of the select in order for events to work. */

        let recruitingDisabledSelect = (
        <Select variant="filled" value={this.state.transactionType} onChange={this.handleTransactionChange} style={{margin: '7px', minWidth: '15em' }}>
            <MenuItem value="buyer">Buyer transaction</MenuItem>
            <MenuItem value="seller">Seller transaction</MenuItem>
            <MenuItem value="buyerAndSeller">Buyer and Seller transaction</MenuItem>
            <BigTooltip placement="left" arrow title="You cannot create more than one recruit at a time.  To make changes, just edit the existing recruit.">
                {/* Need wrapper div in order that mouseover will still trigger the tooltip display.
                    Need click handler in order that we don't spuriously trigger the select. */}
                <div onClick={() => {}}>
                    <MenuItem value="new" disabled><em>New recruit</em></MenuItem>
                    <MenuItem value="experienced" disabled><em>Experienced recruit</em></MenuItem>
                </div>
            </BigTooltip>
        </Select> );

        let recruitingEnabledSelect = (
        <Select variant="filled" value={this.state.transactionType} onChange={this.handleTransactionChange} style={{margin: '7px', minWidth: '15em' }}>
            <MenuItem value="buyer">Buyer transaction</MenuItem>
            <MenuItem value="seller">Seller transaction</MenuItem>
            <MenuItem value="buyerAndSeller">Buyer and Seller transaction</MenuItem>
            <MenuItem value="new">New recruit</MenuItem>
            <MenuItem value="experienced">Experienced recruit</MenuItem>
        </Select> );


        let transactionSelect = this.state.recruitingDisabled
            ? recruitingDisabledSelect
            : recruitingEnabledSelect;

        let createButton = <AsyncCustomButton onClick={this.handleButtonClick}>Create</AsyncCustomButton>;

        let content;

        if (this.state.transactionType == "buyerAndSeller") {            
            content = (
            <div>
                Create new {transactionSelect} for {this.state.leadName}:
                <div>
                    <div style={{float: 'right', padding: 20, paddingTop: 30}}>
                        {createButton}
                    </div>
                    <ol style={{float: 'right'}}>
                        <li>Buyer transaction assigned to {this.agents("buyer")}</li>
                        <li>Seller transaction assigned to {this.agents("seller")}</li>
                    </ol>
                </div>
            </div>);
        }
        else {
            content = (<div>
                Create new {transactionSelect} for {this.state.leadName} assigned to {this.agents(this.state.transactionType)}
                {createButton}
            </div>);
        }

        return content;
    }
}