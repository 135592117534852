import React, { Component } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { AsyncCustomButton } from './AsyncCustomButton';

export class TransactionMadlib extends Component {
    static displayName = TransactionMadlib.name;

    constructor(props) {
        super(props);

        this.state = {
            leadName: props.leadName,
            transactionType: props.defaultTransactionType,

            agents: props.agents,
            
            selectedAgents: {
                buyer: props.defaultBuyerAgent,
                seller: props.defaultSellerAgent
            }
        };

        this.handleTransactionChange = this.handleTransactionChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);

        /*
            Our props will need:
                Lead Name
                List of agents (for now use same for buyer/seller)
                Default transaction type
        */
    }

    static getDerivedStateFromProps(props, state) {
        /* TODO:  apparently this should compare existing state/props first
            so that we don't load too much
        */

        let newState = {
            leadName: props.leadName,
            agents: props.agents,
            selectedAgents: {
                buyer: state.selectedAgents.buyer,
                seller: state.selectedAgents.seller
            }
        };

        if (state.selectedAgents.buyer == 0) {
            newState.selectedAgents.buyer = props.defaultBuyerAgent;
        }

        if (state.selectedAgents.seller == 0) {
            newState.selectedAgents.seller = props.defaultSellerAgent;
        }
        
        return newState;
    }

    handleTransactionChange(e) {
        let transactionType = e.target.value;
        
        this.setState((state, props) => ({
            transactionType: transactionType
        }));
    }

    handleAgentChange(transactionType, e){
        let assignedAgent = e.target.value;
        this.setState((state, props) => {
            
            let newAgents = {
                buyer: state.selectedAgents.buyer,
                seller: state.selectedAgents.seller
            };

            newAgents[transactionType] = assignedAgent;
            return {selectedAgents: newAgents};
        });
    }

    async handleButtonClick(e) {
        let transactions = [];
        
        if (this.state.transactionType == "buyerAndSeller") {
            transactions.push({transactionType: "buyer", agentId: this.state.selectedAgents.buyer});
            transactions.push({transactionType: "seller", agentId: this.state.selectedAgents.seller});
        } else {
            transactions.push({transactionType: this.state.transactionType, agentId: this.state.selectedAgents[this.state.transactionType]});
        }

        if (this.props.callback) {
            await this.props.callback(transactions);
        }
    }

    agents(transactionType) {
        let selectedValue = this.state.selectedAgents[transactionType];
        let agents = this.state.agents.map((agent) => <MenuItem key={agent.agentId} value={agent.agentId}>{agent.name}</MenuItem>);
        
        return (<Select variant="filled" value={selectedValue} onChange={(e) => this.handleAgentChange(transactionType, e)} style={{margin: '7px' }}>
            {agents}
        </Select>);
    }

    render() {
        let transactionSelect = (
        <Select variant="filled" value={this.state.transactionType} onChange={this.handleTransactionChange} style={{margin: '7px' }}>
            <MenuItem value="buyer">Buyer</MenuItem>
            <MenuItem value="seller">Seller</MenuItem>
            <MenuItem value="buyerAndSeller">Buyer and Seller</MenuItem>
        </Select> );

        let createButton = <AsyncCustomButton onClick={this.handleButtonClick}>Create</AsyncCustomButton>;

        let content;

        if (this.state.transactionType == "buyerAndSeller") {            
            content = (
            <div>
                Create new {transactionSelect} transactions for {this.state.leadName}:
                <div>
                    <div style={{float: 'right', padding: 20, paddingTop: 30}}>
                        {createButton}
                    </div>
                    <ol style={{float: 'right'}}>
                        <li>Buyer transaction assigned to {this.agents("buyer")}</li>
                        <li>Seller transaction assigned to {this.agents("seller")}</li>
                    </ol>
                </div>
            </div>);
        }
        else {
            content = (<div>
                Create new {transactionSelect} transaction for {this.state.leadName} assigned to {this.agents(this.state.transactionType)}
                {createButton}
            </div>);
        }

        return content;
    }
}